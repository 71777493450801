export const ReadAbscences = () => import('./Abscences/ReadAbscences/ReadAbscences');
export const ReadAchatsforfaits = () => import('./Achatsforfaits/ReadAchatsforfaits/ReadAchatsforfaits');
export const ReadActions = () => import('./Actions/ReadActions/ReadActions');
export const ReadAlarms = () => import('./Alarms/ReadAlarms/ReadAlarms');
export const ReadAlldatas = () => import('./Alldatas/ReadAlldatas/ReadAlldatas');
export const ReadAnalysespointeuses = () => import('./Analysespointeuses/ReadAnalysespointeuses/ReadAnalysespointeuses');
export const ReadApprovisionementdetails = () => import('./Approvisionementdetails/ReadApprovisionementdetails/ReadApprovisionementdetails');
export const ReadAssignations = () => import('./Assignations/ReadAssignations/ReadAssignations');
export const ReadBadges = () => import('./Badges/ReadBadges/ReadBadges');
export const ReadBagages = () => import('./Bagages/ReadBagages/ReadBagages');
export const ReadBalises = () => import('./Balises/ReadBalises/ReadBalises');
export const ReadCartes = () => import('./Cartes/ReadCartes/ReadCartes');
export const ReadCategories = () => import('./Categories/ReadCategories/ReadCategories');
export const ReadClients = () => import('./Clients/ReadClients/ReadClients');
export const ReadConfigurations = () => import('./Configurations/ReadConfigurations/ReadConfigurations');
export const ReadConges = () => import('./Conges/ReadConges/ReadConges');
export const ReadContrats = () => import('./Contrats/ReadContrats/ReadContrats');
export const ReadContratsclients = () => import('./Contratsclients/ReadContratsclients/ReadContratsclients');
export const ReadControlles = () => import('./Controlles/ReadControlles/ReadControlles');
export const ReadControlleursacces = () => import('./Controlleursacces/ReadControlleursacces/ReadControlleursacces');
export const ReadCredits = () => import('./Credits/ReadCredits/ReadCredits');
export const ReadCruds = () => import('./Cruds/ReadCruds/ReadCruds');
export const ReadDaysprogrammes = () => import('./Daysprogrammes/ReadDaysprogrammes/ReadDaysprogrammes');
export const ReadDaysprogrammestaches = () => import('./Daysprogrammestaches/ReadDaysprogrammestaches/ReadDaysprogrammestaches');
export const ReadDebits = () => import('./Debits/ReadDebits/ReadDebits');
export const ReadDependances = () => import('./Dependances/ReadDependances/ReadDependances');
export const ReadDeplacements = () => import('./Deplacements/ReadDeplacements/ReadDeplacements');
export const ReadDeploiementspointeusesmoyenstransports = () => import('./Deploiementspointeusesmoyenstransports/ReadDeploiementspointeusesmoyenstransports/ReadDeploiementspointeusesmoyenstransports');
export const ReadDirections = () => import('./Directions/ReadDirections/ReadDirections');
export const ReadEchelons = () => import('./Echelons/ReadEchelons/ReadEchelons');
export const ReadEntreprises = () => import('./Entreprises/ReadEntreprises/ReadEntreprises');
export const ReadEtapes = () => import('./Etapes/ReadEtapes/ReadEtapes');
export const ReadExports = () => import('./Exports/ReadExports/ReadExports');
export const ReadExportsdetails = () => import('./Exportsdetails/ReadExportsdetails/ReadExportsdetails');
export const ReadExtrasdatas = () => import('./Extrasdatas/ReadExtrasdatas/ReadExtrasdatas');
export const ReadFiles = () => import('./Files/ReadFiles/ReadFiles');
export const ReadFonctions = () => import('./Fonctions/ReadFonctions/ReadFonctions');
export const ReadForfaits = () => import('./Forfaits/ReadForfaits/ReadForfaits');
export const ReadHeadselements = () => import('./Headselements/ReadHeadselements/ReadHeadselements');
export const ReadHistoriques = () => import('./Historiques/ReadHistoriques/ReadHistoriques');
export const ReadHomes = () => import('./Homes/ReadHomes/ReadHomes');
export const ReadHoraireagents = () => import('./Horaireagents/ReadHoraireagents/ReadHoraireagents');
export const ReadHoraires = () => import('./Horaires/ReadHoraires/ReadHoraires');
export const ReadHorairesglobalspostes = () => import('./Horairesglobalspostes/ReadHorairesglobalspostes/ReadHorairesglobalspostes');
export const ReadHorairestypespostes = () => import('./Horairestypespostes/ReadHorairestypespostes/ReadHorairestypespostes');
export const ReadHorairestypessites = () => import('./Horairestypessites/ReadHorairestypessites/ReadHorairestypessites');
export const ReadIdentifications = () => import('./Identifications/ReadIdentifications/ReadIdentifications');
export const ReadImports = () => import('./Imports/ReadImports/ReadImports');
export const ReadJobs = () => import('./Jobs/ReadJobs/ReadJobs');
export const ReadJoursferies = () => import('./Joursferies/ReadJoursferies/ReadJoursferies');
export const ReadLignes = () => import('./Lignes/ReadLignes/ReadLignes');
export const ReadLignesmoyenstransports = () => import('./Lignesmoyenstransports/ReadLignesmoyenstransports/ReadLignesmoyenstransports');
export const ReadListingsetats = () => import('./Listingsetats/ReadListingsetats/ReadListingsetats');
export const ReadListingsvalider0stats = () => import('./Listingsvalider0stats/ReadListingsvalider0stats/ReadListingsvalider0stats');
export const ReadLogs = () => import('./Logs/ReadLogs/ReadLogs');
export const ReadMaintenances = () => import('./Maintenances/ReadMaintenances/ReadMaintenances');
export const ReadMaps = () => import('./Maps/ReadMaps/ReadMaps');
export const ReadMatrimoniales = () => import('./Matrimoniales/ReadMatrimoniales/ReadMatrimoniales');
export const ReadMenus = () => import('./Menus/ReadMenus/ReadMenus');
export const ReadModel_has_permissions = () => import('./Model_has_permissions/ReadModel_has_permissions/ReadModel_has_permissions');
export const ReadModelslistings = () => import('./Modelslistings/ReadModelslistings/ReadModelslistings');
export const ReadMoyenstransports = () => import('./Moyenstransports/ReadMoyenstransports/ReadMoyenstransports');
export const ReadMoyenstransportspointeuses = () => import('./Moyenstransportspointeuses/ReadMoyenstransportspointeuses/ReadMoyenstransportspointeuses');
export const ReadNationalites = () => import('./Nationalites/ReadNationalites/ReadNationalites');
export const ReadOauth_access_tokens = () => import('./Oauth_access_tokens/ReadOauth_access_tokens/ReadOauth_access_tokens');
export const ReadOauth_auth_codes = () => import('./Oauth_auth_codes/ReadOauth_auth_codes/ReadOauth_auth_codes');
export const ReadOauth_clients = () => import('./Oauth_clients/ReadOauth_clients/ReadOauth_clients');
export const ReadOauth_personal_access_clients = () => import('./Oauth_personal_access_clients/ReadOauth_personal_access_clients/ReadOauth_personal_access_clients');
export const ReadOauth_refresh_tokens = () => import('./Oauth_refresh_tokens/ReadOauth_refresh_tokens/ReadOauth_refresh_tokens');
export const ReadPassagesrondes = () => import('./Passagesrondes/ReadPassagesrondes/ReadPassagesrondes');
export const ReadPastilles = () => import('./Pastilles/ReadPastilles/ReadPastilles');
export const ReadPermissions = () => import('./Permissions/ReadPermissions/ReadPermissions');
export const ReadPerms = () => import('./Perms/ReadPerms/ReadPerms');
export const ReadPointages = () => import('./Pointages/ReadPointages/ReadPointages');
export const ReadPointeuses = () => import('./Pointeuses/ReadPointeuses/ReadPointeuses');
export const ReadPointeusestransactions = () => import('./Pointeusestransactions/ReadPointeusestransactions/ReadPointeusestransactions');
export const ReadPoints = () => import('./Points/ReadPoints/ReadPoints');
export const ReadPositions = () => import('./Positions/ReadPositions/ReadPositions');
export const ReadPostes = () => import('./Postes/ReadPostes/ReadPostes');
export const ReadPostesarticles = () => import('./Postesarticles/ReadPostesarticles/ReadPostesarticles');
export const ReadPostesglobals = () => import('./Postesglobals/ReadPostesglobals/ReadPostesglobals');
export const ReadPostespointeuses = () => import('./Postespointeuses/ReadPostespointeuses/ReadPostespointeuses');
export const ReadPostestats = () => import('./Postestats/ReadPostestats/ReadPostestats');
export const ReadPresence_par_date = () => import('./Presence_par_date/ReadPresence_par_date/ReadPresence_par_date');
export const ReadPresences = () => import('./Presences/ReadPresences/ReadPresences');
export const ReadPreuves = () => import('./Preuves/ReadPreuves/ReadPreuves');
export const ReadProgrammations = () => import('./Programmations/ReadProgrammations/ReadProgrammations');
export const ReadProgrammes = () => import('./Programmes/ReadProgrammes/ReadProgrammes');
export const ReadProjets = () => import('./Projets/ReadProjets/ReadProjets');
export const ReadProvinces = () => import('./Provinces/ReadProvinces/ReadProvinces');
export const ReadRapportpostes = () => import('./Rapportpostes/ReadRapportpostes/ReadRapportpostes');
export const ReadRapportsautomatiques = () => import('./Rapportsautomatiques/ReadRapportsautomatiques/ReadRapportsautomatiques');
export const ReadReservations = () => import('./Reservations/ReadReservations/ReadReservations');
export const ReadRole_has_permission = () => import('./Role_has_permission/ReadRole_has_permission/ReadRole_has_permission');
export const ReadRole_has_permissions = () => import('./Role_has_permissions/ReadRole_has_permissions/ReadRole_has_permissions');
export const ReadRoles = () => import('./Roles/ReadRoles/ReadRoles');
export const ReadServeurswebs = () => import('./Serveurswebs/ReadServeurswebs/ReadServeurswebs');
export const ReadServices = () => import('./Services/ReadServices/ReadServices');
export const ReadSexes = () => import('./Sexes/ReadSexes/ReadSexes');
export const ReadSites = () => import('./Sites/ReadSites/ReadSites');
export const ReadSitesglobals = () => import('./Sitesglobals/ReadSitesglobals/ReadSitesglobals');
export const ReadSitespointeuses = () => import('./Sitespointeuses/ReadSitespointeuses/ReadSitespointeuses');
export const ReadSitessdeplacements = () => import('./Sitessdeplacements/ReadSitessdeplacements/ReadSitessdeplacements');
export const ReadSitessuperviseurs = () => import('./Sitessuperviseurs/ReadSitessuperviseurs/ReadSitessuperviseurs');
export const ReadSituations = () => import('./Situations/ReadSituations/ReadSituations');
export const ReadSoldables = () => import('./Soldables/ReadSoldables/ReadSoldables');
export const ReadStatszones = () => import('./Statszones/ReadStatszones/ReadStatszones');
export const ReadSupportclientreponses = () => import('./Supportclientreponses/ReadSupportclientreponses/ReadSupportclientreponses');
export const ReadSupportclientrequettes = () => import('./Supportclientrequettes/ReadSupportclientrequettes/ReadSupportclientrequettes');
export const ReadSurveillances = () => import('./Surveillances/ReadSurveillances/ReadSurveillances');
export const ReadSwitchsusers = () => import('./Switchsusers/ReadSwitchsusers/ReadSwitchsusers');
export const ReadTerminals = () => import('./Terminals/ReadTerminals/ReadTerminals');
export const ReadTrackings = () => import('./Trackings/ReadTrackings/ReadTrackings');
export const ReadTraitements = () => import('./Traitements/ReadTraitements/ReadTraitements');
export const ReadTrajets = () => import('./Trajets/ReadTrajets/ReadTrajets');
export const ReadTransactions = () => import('./Transactions/ReadTransactions/ReadTransactions');
export const ReadTransactionsuserssyntheses2 = () => import('./Transactionsuserssyntheses2/ReadTransactionsuserssyntheses2/ReadTransactionsuserssyntheses2');
export const ReadTypes = () => import('./Types/ReadTypes/ReadTypes');
export const ReadTypesabscences = () => import('./Typesabscences/ReadTypesabscences/ReadTypesabscences');
export const ReadTypesagentshoraires = () => import('./Typesagentshoraires/ReadTypesagentshoraires/ReadTypesagentshoraires');
export const ReadTypeseffectifs = () => import('./Typeseffectifs/ReadTypeseffectifs/ReadTypeseffectifs');
export const ReadTypesheures = () => import('./Typesheures/ReadTypesheures/ReadTypesheures');
export const ReadTypesmoyenstransports = () => import('./Typesmoyenstransports/ReadTypesmoyenstransports/ReadTypesmoyenstransports');
export const ReadTypespointages = () => import('./Typespointages/ReadTypespointages/ReadTypespointages');
export const ReadTypespointeuses = () => import('./Typespointeuses/ReadTypespointeuses/ReadTypespointeuses');
export const ReadTypespostes = () => import('./Typespostes/ReadTypespostes/ReadTypespostes');
export const ReadTypessites = () => import('./Typessites/ReadTypessites/ReadTypessites');
export const ReadTypesventilations = () => import('./Typesventilations/ReadTypesventilations/ReadTypesventilations');
export const ReadUsers = () => import('./Users/ReadUsers/ReadUsers');
export const ReadUserszones = () => import('./Userszones/ReadUserszones/ReadUserszones');
export const ReadVacations = () => import('./Vacations/ReadVacations/ReadVacations');
export const ReadValidations = () => import('./Validations/ReadValidations/ReadValidations');
export const ReadVariables = () => import('./Variables/ReadVariables/ReadVariables');
export const ReadVilles = () => import('./Villes/ReadVilles/ReadVilles');
export const ReadVilleszones = () => import('./Villeszones/ReadVilleszones/ReadVilleszones');
export const ReadWebsockets_statistics_entries = () => import('./Websockets_statistics_entries/ReadWebsockets_statistics_entries/ReadWebsockets_statistics_entries');
export const ReadWorks = () => import('./Works/ReadWorks/ReadWorks');
export const ReadZones = () => import('./Zones/ReadZones/ReadZones');
