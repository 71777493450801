export default [
  {
    "text": "Usagers",
    "icon": "ti-users-group",
    "route": "usagers",
    "permission": "Voir la page d'acceuil",
    "module": "Effectif"
  },
  {
    "text": "Cartes",
    "icon": "ti-credit-card",
    "route": "cartes",
    "permission": "Voir la page d'acceuil",
    "module": "Effectif"
  },
  // {
  //   "text": "Agences ",
  //   "icon": "ti-layout",
  //   "route": "sites",
  //   "params": {
  //     "type": "journal"
  //   },
  //   "permission": "Voir la page des pointages brutes"
  // },
 
  {
    "text": "Transactions",
    "icon": "ti-arrows-transfer-down",
    "route": "",
    "submenu": [
      {
        "text": "Terminaux",
        "icon": "ti-devices",
        "route": "terminaux",
        "params": {
          "type": "journal"
        },
        "permission": "Voir la page des pointages brutes"
      },
      
      { 
        "text": "Journal Sessions",
        "icon": "ti-transfer",
        "route": "journalssessions",
        "params": {
          "type": "journal"
        },
        "permission": "Voir la page des pointages brutes"
      },
      { 
        "text": "Journals",
        "icon": "ti-transfer",
        "route": "journalstransactions",
        "params": {
          "type": "journal"
        },
        "permission": "Voir la page des pointages brutes"
      },
      
      {
        "text": "Forfaits",
        "icon": "ti-credit-card-pay",
        "route": "achatforfait",
       "permission": "Voir la page des pointages brutes",
       "params": {
          "type": "journal"
        },
        // "submenu":[
        //   {
        //     "text": "Forfaits",
        //     "icon": "ti-credit-card-refund",
        //     "route": "forfaits",
        //     "params": {
        //       "type": "journal"
        //     },
        
        //   },
        //   {
        //     "text": "Achat Forfaits",
        //     "icon": "ti-cash-register",
        //     "route": "achatforfait",
        //     "params": {
        //       "type": "journal"
        //     },
        //     "permission": "Voir la page des pointages brutes"
        //   }
        // ]
      },
    ]
  }
  ,
  {
    "text": "Transports ",
    "icon": "ti-arrows-transfer-down",
    "route": "",
    "submenu": [
      // {
      //   "text": "Vehicules",
      //   "icon": "ti-bus",
      //   "route": "moyenstransports",
      //   "params": {
      //     "type": "journal"
      //   },
      //   "permission": "Voir la page des pointages brutes"
      // },
    
      {
        "text": "Trajets Urbains",
        "icon": "ti-bus-stop",
        "route": "urbains",
        "params": {
          "type": "journal"
        },
        "permission": "Voir la page des pointages brutes"
      },
      {
        "text": "Trajets Interurbains",
        "icon": "ti-directions",
        "route": "interurbains",
        "params": {
          "type": "journal"
        },
        "permission": "Voir la page des pointages brutes"
      },
      // {
      //   "text": "Lignes ",
      //   "icon": "ti-route",
      //   "route": "lignes",
      //   "params": {
      //     "type": "journal"
      //   },
      //   "permission": "Voir la page des pointages brutes"
      // },
      // {
      //   "text": "Types equipements",
      //   "icon": "ti-layout",
      //   "route": "typesequipements",
      //   "params": {
      //     "type": "journal"
      //   },
      //   "permission": "Voir la page des pointages brutes"
      // },
      // {
      //   "text": "Pointeuses",
      //   "icon": "ti-layout",
      //   "route": "pointeuses",
      //   "params": {
      //     "type": "journal"
      //   },
      //   "permission": "Voir la page des pointages brutes"
      // },
    ]
  },
  {
    "text": "Configurations",
    "icon": "ti-settings",
    "route": "configurations",
    "permission": "Voir la page de Configurations",
    "module": "Configuration",
  },
  {
    "text": "Administration utilisateurs ",
    "icon": "ti-lock",
    "route": "",
    "module": "Administration",
    "submenu": [
      {
        "text": "Les users",
        "route": "users",
        "icon": "ti-user",
        "permission": "Voir la page des utilisateurs"
      },
      {
        "text": "Les super admin",
        "route": "Users_SU",
        "icon": "ti-shield",
        "permission": "Voir la page des superAdmin",
        "isSu": true
      },
      {
        "text": "Roles / Profils",
        "route": "roles",
        "icon": "ti-scan-eye",
        "params": {
          "type": "brute"
        },
        "permission": "Voir la page des roles"
      },
      {
        "text": "Permissions",
        "route": "permissions",
        "icon": "ti-shield-lock",
        "isSu": true,
        "params": {
          "type": "brute"
        },
        "permission": "Voir la page des permissions"
      },
      {
        "text": "Logs",
        "route": "surveillances",
        "icon": "ti-topology-full",
        "permission": "Voir la page des activites recentes"
      },
      {
        "text": "Entreprises",
        "route": "entreprises",
        "icon": "ti-layout",
        "isSu": true
      }
    ]
  }
]
