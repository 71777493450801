export const ReadAbscences = () => import('./Abscences/AbscencesView');

export const ReadAchatsforfaits = () => import('./Achatsforfaits/AchatsforfaitsView');

export const ReadActions = () => import('./Actions/ActionsView');

export const ReadAlarms = () => import('./Alarms/AlarmsView');

export const ReadAlldatas = () => import('./Alldatas/AlldatasView');

export const ReadAnalysespointeuses = () => import('./Analysespointeuses/AnalysespointeusesView');

export const ReadApprovisionementdetails = () => import('./Approvisionementdetails/ApprovisionementdetailsView');

export const ReadAssignations = () => import('./Assignations/AssignationsView');

export const ReadBadges = () => import('./Badges/BadgesView');

export const ReadBalises = () => import('./Balises/BalisesView');

export const ReadCartes = () => import('./Cartes/CartesView');

export const ReadCategories = () => import('./Categories/CategoriesView');

export const ReadClients = () => import('./Clients/ClientsView');

export const ReadConfigurations = () => import('./Configurations/ConfigurationsView');

export const ReadConges = () => import('./Conges/CongesView');

export const ReadContrats = () => import('./Contrats/ContratsView');

export const ReadContratsclients = () => import('./Contratsclients/ContratsclientsView');


export const ReadControlleursacces = () => import('./Controlleursacces/ControlleursaccesView');

export const ReadCredits = () => import('./Credits/CreditsView');

export const ReadCruds = () => import('./Cruds/CrudsView');

export const ReadDebits = () => import('./Debits/DebitsView');

export const ReadDependances = () => import('./Dependances/DependancesView');

export const ReadDeplacements = () => import('./Deplacements/DeplacementsView');

export const ReadDeploiementspointeusesmoyenstransports = () => import('./Deploiementspointeusesmoyenstransports/DeploiementspointeusesmoyenstransportsView');

export const ReadDirections = () => import('./Directions/DirectionsView');

export const ReadEchelons = () => import('./Echelons/EchelonsView');

export const ReadEntreprises = () => import('./Entreprises/EntreprisesView');

export const ReadEtapes = () => import('./Etapes/EtapesView');

export const ReadExports = () => import('./Exports/ExportsView');

export const ReadExportsdetails = () => import('./Exportsdetails/ExportsdetailsView');

export const ReadExtrasdatas = () => import('./Extrasdatas/ExtrasdatasView');

export const ReadFiles = () => import('./Files/FilesView');

export const ReadFonctions = () => import('./Fonctions/FonctionsView');

export const ReadForfaits = () => import('./Forfaits/ForfaitsView');

export const ReadHeadselements = () => import('./Headselements/HeadselementsView');

export const ReadHistoriques = () => import('./Historiques/HistoriquesView');

export const ReadHomes = () => import('./Homes/HomesView');

export const ReadHoraireagents = () => import('./Horaireagents/HoraireagentsView');

export const ReadHoraires = () => import('./Horaires/HorairesView');

export const ReadHorairesglobalspostes = () => import('./Horairesglobalspostes/HorairesglobalspostesView');

export const ReadHorairestypespostes = () => import('./Horairestypespostes/HorairestypespostesView');

export const ReadHorairestypessites = () => import('./Horairestypessites/HorairestypessitesView');

export const ReadIdentifications = () => import('./Identifications/IdentificationsView');

export const ReadImports = () => import('./Imports/ImportsView');

export const ReadJobs = () => import('./Jobs/JobsView');

export const ReadJournals = () => import('./Journals/JournalsView');
export const ReadJoursferies = () => import('./Joursferies/JoursferiesView');
export const ReadJournalsTransactions = () => import('./JournalsTransactions/JournalsTransactionsView');
export const ReadJournalsSessions = () => import('./JournalsSessions/JournalsSessionsView');
export const ReadLignes = () => import('./Lignes/LignesView');

export const ReadLignesmoyenstransports = () => import('./Lignesmoyenstransports/LignesmoyenstransportsView');

export const ReadListingsetats = () => import('./Listingsetats/ListingsetatsView');

export const ReadListingsvalider0stats = () => import('./Listingsvalider0stats/Listingsvalider0statsView');

export const ReadLogs = () => import('./Logs/LogsView');
export const ReadLogins = () => import('./Logins/LoginsView');

export const ReadMaintenances = () => import('./Maintenances/MaintenancesView');

export const ReadMaps = () => import('./Maps/MapsView');

export const ReadMatrimoniales = () => import('./Matrimoniales/MatrimonialesView');

export const ReadMenus = () => import('./Menus/MenusView');

export const ReadModel_has_permissions = () => import('./Model_has_permissions/Model_has_permissionsView');

export const ReadModelslistings = () => import('./Modelslistings/ModelslistingsView');

export const ReadMoyenstransports = () => import('./Moyenstransports/MoyenstransportsView');

export const ReadMoyenstransportspointeuses = () => import('./Moyenstransportspointeuses/MoyenstransportspointeusesView');

export const ReadNationalites = () => import('./Nationalites/NationalitesView');

export const ReadOauth_access_tokens = () => import('./Oauth_access_tokens/Oauth_access_tokensView');

export const ReadOauth_auth_codes = () => import('./Oauth_auth_codes/Oauth_auth_codesView');

export const ReadOauth_clients = () => import('./Oauth_clients/Oauth_clientsView');

export const ReadOauth_personal_access_clients = () => import('./Oauth_personal_access_clients/Oauth_personal_access_clientsView');

export const ReadOauth_refresh_tokens = () => import('./Oauth_refresh_tokens/Oauth_refresh_tokensView');

export const ReadPassagesrondes = () => import('./Passagesrondes/PassagesrondesView');

export const ReadPastilles = () => import('./Pastilles/PastillesView');

export const ReadPermissions = () => import('./Permissions/PermissionsView');

export const ReadPerms = () => import('./Perms/PermsView');

export const ReadPointages = () => import('./Pointages/PointagesView');

export const ReadPointeuses = () => import('./Pointeuses/PointeusesView');

export const ReadPointeusestransactions = () => import('./Pointeusestransactions/PointeusestransactionsView');

export const ReadPoints = () => import('./Points/PointsView');

export const ReadPositions = () => import('./Positions/PositionsView');

export const ReadPostes = () => import('./Postes/PostesView');

export const ReadPostesarticles = () => import('./Postesarticles/PostesarticlesView');

export const ReadPostesglobals = () => import('./Postesglobals/PostesglobalsView');

export const ReadPostespointeuses = () => import('./Postespointeuses/PostespointeusesView');

export const ReadPresences = () => import('./Presences/PresencesView');

export const ReadPreuves = () => import('./Preuves/PreuvesView');

export const ReadProgrammations = () => import('./Programmations/ProgrammationsView');

export const ReadProgrammes = () => import('./Programmes/ProgrammesView');

export const ReadProjets = () => import('./Projets/ProjetsView');

export const ReadProvinces = () => import('./Provinces/ProvincesView');

export const ReadRapports = () => import('./Rapports/RapportsView');
export const ReadRapportpostes = () => import('./Rapportpostes/RapportpostesView');

export const ReadRapportsautomatiques = () => import('./Rapportsautomatiques/RapportsautomatiquesView');

export const ReadReservations = () => import('./Reservations/ReservationsView');


export const ReadRole_has_permissions = () => import('./Role_has_permissions/Role_has_permissionsView');

export const ReadRoles = () => import('./Roles/RolesView');


// export const ReadServices = () => import('./Services/ServicesView');

export const ReadSexes = () => import('./Sexes/SexesView');

export const ReadSites = () => import('./Sites/SitesView');

export const ReadSitesglobals = () => import('./Sitesglobals/SitesglobalsView');

export const ReadSitespointeuses = () => import('./Sitespointeuses/SitespointeusesView');

export const ReadSitessdeplacements = () => import('./Sitessdeplacements/SitessdeplacementsView');


export const ReadSituations = () => import('./Situations/SituationsView');

export const ReadSoldables = () => import('./Soldables/SoldablesView');

export const ReadStatszones = () => import('./Statszones/StatszonesView');

export const ReadSupportclientreponses = () => import('./Supportclientreponses/SupportclientreponsesView');

export const ReadSupportclientrequettes = () => import('./Supportclientrequettes/SupportclientrequettesView');

export const ReadSurveillances = () => import('./Surveillances/SurveillancesView');

export const ReadSwitchsusers = () => import('./Switchsusers/SwitchsusersView');

export const ReadTerminals = () => import('./Terminals/TerminalsView');
export const ReadTerminaux = () => import('./Terminaux/TerminauxView');

export const ReadTrackings = () => import('./Trackings/TrackingsView');


export const ReadTrajets = () => import('./Trajets/TrajetsView');

export const ReadTransactions = () => import('./Transactions/TransactionsView');

export const ReadTypes = () => import('./Types/TypesView');

export const ReadTypesabscences = () => import('./Typesabscences/TypesabscencesView');

export const ReadTypesagentshoraires = () => import('./Typesagentshoraires/TypesagentshorairesView');

export const ReadTypeseffectifs = () => import('./Typeseffectifs/TypeseffectifsView');

export const ReadTypesheures = () => import('./Typesheures/TypesheuresView');

export const ReadTypesmoyenstransports = () => import('./Typesmoyenstransports/TypesmoyenstransportsView');

export const ReadTypespointages = () => import('./Typespointages/TypespointagesView');

export const ReadTypespointeuses = () => import('./Typespointeuses/TypespointeusesView');

export const ReadTypespostes = () => import('./Typespostes/TypespostesView');

export const ReadTypessites = () => import('./Typessites/TypessitesView');

export const ReadTypesventilations = () => import('./Typesventilations/TypesventilationsView');
export const ReadVentilations = () => import('./Ventilations/VentilationsView');

export const ReadAgents = () => import('./Agents/AgentsView');
export const ReadUsagers = () => import('./Usagers/UsagersView');

export const ReadUsers = () => import('./Users/UsersView');

export const ReadUserszones = () => import('./Userszones/UserszonesView');

export const ReadVacations = () => import('./Vacations/VacationsView');

export const ReadValidations = () => import('./Validations/ValidationsView');

export const ReadVariables = () => import('./Variables/VariablesView');

export const ReadVilles = () => import('./Villes/VillesView');

export const ReadVoyages = () => import('./Voyages/VoyagesView');

export const ReadVilleszones = () => import('./Villeszones/VilleszonesView');

export const ReadWebsockets_statistics_entries = () => import('./Websockets_statistics_entries/Websockets_statistics_entriesView');

export const ReadWorks = () => import('./Works/WorksView');

export const ReadZones = () => import('./Zones/ZonesView');

