import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import * as AllViews from '@/views';

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/customs/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
        {
            path: '/',
            name: 'home',
            component: ()=> AllViews.Imports.ReadHomes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Home',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/login',
            name: 'login',
            component: ()=> AllViews.Imports.ReadLogins(),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/abscences',
            name: 'abscences',
            component: ()=> AllViews.Imports.ReadAbscences(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Abscences',
                breadcrumb: [
                    {
                        text: 'Abscences',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/agentsrapports',
            name: 'agentsrapports',
            component: ()=> AllViews.Imports.ReadAgentsrapports(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Agentsrapports',
                breadcrumb: [
                    {
                        text: 'Agentsrapports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/agents',
            name: 'agents',
            component: ()=> AllViews.Imports.ReadAgents(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Agents',
                breadcrumb: [
                    {
                        text: 'Agents',
                        active: true,
                    },
                ],
                filter: [{
                    type: "valider",
                }
                ],
            },
        },
        {
            path: '/attributions',
            name: 'attributions',
            component: ()=> AllViews.Imports.ReadAttributions(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Attributions',
                breadcrumb: [
                    {
                        text: 'Attributions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/badges',
            name: 'badges',
            component: ()=> AllViews.Imports.ReadBadges(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Badges',
                breadcrumb: [
                    {
                        text: 'Badges',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/balises',
            name: 'balises',
            component: ()=> AllViews.Imports.ReadBalises(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Balises',
                breadcrumb: [
                    {
                        text: 'Balises',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/achatforfait',
            name: 'achatforfait',
            component: ()=> AllViews.Imports.ReadAchatsforfaits(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Achat Forfait',
                breadcrumb: [
                    {
                        text: 'Achat Forfait',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/cartes',
            name: 'cartes',
            component: ()=> AllViews.Imports.ReadCartes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Cartes MiFare',
                breadcrumb: [
                    {
                        text: 'Cartes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/categories',
            name: 'categories',
            component: ()=> AllViews.Imports.ReadCategories(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Categories',
                breadcrumb: [
                    {
                        text: 'Categories',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/clients',
            name: 'clients',
            component: ()=> AllViews.Imports.ReadClients(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Clients',
                breadcrumb: [
                    {
                        text: 'Clients',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/configurations',
            name: 'configurations',
            component: ()=> AllViews.Imports.ReadConfigurations(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Configurations',
                breadcrumb: [
                    {
                        text: 'Configurations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/conges',
            name: 'conges',
            component: ()=> AllViews.Imports.ReadConges(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Conges',
                breadcrumb: [
                    {
                        text: 'Conges',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/contrats',
            name: 'contrats',
            component: ()=> AllViews.Imports.ReadContrats(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Contrats',
                breadcrumb: [
                    {
                        text: 'Contrats',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/contratsclients',
            name: 'contratsclients',
            component: ()=> AllViews.Imports.ReadContratsclients(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Contratsclients',
                breadcrumb: [
                    {
                        text: 'Contratsclients',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/controlleursacces',
            name: 'controlleursacces',
            component: ()=> AllViews.Imports.ReadControlleursacces(),
            meta: {
                requiresAuth: true,
                pageTitle: "Controlleur d'acces",
                breadcrumb: [
                    {
                        text: 'Controlleursacces',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/credits',
            name: 'credits',
            component: ()=> AllViews.Imports.ReadCredits(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Credits',
                breadcrumb: [
                    {
                        text: 'Credits',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/cruds',
            name: 'cruds',
            component: ()=> AllViews.Imports.ReadCruds(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Cruds',
                breadcrumb: [
                    {
                        text: 'Cruds',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/debits',
            name: 'debits',
            component: ()=> AllViews.Imports.ReadDebits(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Debits',
                breadcrumb: [
                    {
                        text: 'Debits',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/deplacements',
            name: 'deplacements',
            component: ()=> AllViews.Imports.ReadDeplacements(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Deplacements',
                breadcrumb: [
                    {
                        text: 'Deplacements',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/deploiementspointeusesmoyenstransports',
            name: 'deploiementspointeusesmoyenstransports',
            component: ()=> AllViews.Imports.ReadDeploiementspointeusesmoyenstransports(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Deploiementspointeusesmoyenstransports',
                breadcrumb: [
                    {
                        text: 'Deploiementspointeusesmoyenstransports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/details',
            name: 'details',
            component: ()=> AllViews.Imports.ReadDetails(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Details',
                breadcrumb: [
                    {
                        text: 'Details',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/directions',
            name: 'directions',
            component: ()=> AllViews.Imports.ReadAgents(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Directions',
                breadcrumb: [
                    {
                        text: 'Directions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/doublonsPostes',
            name: 'doublonsPostes',
            component: ()=> AllViews.Imports.ReadDoublonsPostes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'DoublonsPostes',
                breadcrumb: [
                    {
                        text: 'DoublonsPostes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/echelons',
            name: 'echelons',
            component: ()=> AllViews.Imports.ReadEchelons(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Echelons',
                breadcrumb: [
                    {
                        text: 'Echelons',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/entreprises',
            name: 'entreprises',
            component: ()=> AllViews.Imports.ReadEntreprises(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Entreprises',
                breadcrumb: [
                    {
                        text: 'Entreprises',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/extrasdatas',
            name: 'extrasdatas',
            component: ()=> AllViews.Imports.ReadExtrasdatas(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Extrasdatas',
                breadcrumb: [
                    {
                        text: 'Extrasdatas',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/forfaits',
            name: 'forfaits',
            component: ()=> AllViews.Imports.ReadForfaits(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Forfaits',
                breadcrumb: [
                    {
                        text: 'Forfaits',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/files',
            name: 'files',
            component: ()=> AllViews.Imports.ReadFiles(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Files',
                breadcrumb: [
                    {
                        text: 'Files',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/fonctions',
            name: 'fonctions',
            component: ()=> AllViews.Imports.ReadFonctions(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Fonctions',
                breadcrumb: [
                    {
                        text: 'Fonctions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/graphiques',
            name: 'graphiques',
            component: ()=> AllViews.Imports.ReadGraphiques(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Graphiques',
                breadcrumb: [
                    {
                        text: 'Graphiques',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/historiques',
            name: 'historiques',
            component: ()=> AllViews.Imports.ReadHistoriques(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Historiques',
                breadcrumb: [
                    {
                        text: 'Historiques',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/historiquecartes',
            name: 'historiquecartes',
            component: ()=> AllViews.Imports.ReadHistoriquecartes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Historique de cartes',
                breadcrumb: [
                    {
                        text: 'Historiquecartes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/homes',
            name: 'homes',
            component: ()=> AllViews.Imports.ReadHomes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Homes',
                breadcrumb: [
                    {
                        text: 'Homes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/homezones',
            name: 'homezones',
            component: ()=> AllViews.Imports.ReadHomezones(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Homezones',
                breadcrumb: [
                    {
                        text: 'Homezones',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/horaireagents',
            name: 'horaireagents',
            component: ()=> AllViews.Imports.ReadHoraireagents(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Horaireagents',
                breadcrumb: [
                    {
                        text: 'Horaireagents',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/horaires',
            name: 'horaires',
            component: ()=> AllViews.Imports.ReadHoraires(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Horaires',
                breadcrumb: [
                    {
                        text: 'Horaires',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/horairestypespostes',
            name: 'horairestypespostes',
            component: ()=> AllViews.Imports.ReadHorairestypespostes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Horairestypespostes',
                breadcrumb: [
                    {
                        text: 'Horairestypespostes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/horairestypessites',
            name: 'horairestypessites',
            component: ()=> AllViews.Imports.ReadHorairestypessites(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Horairestypessites',
                breadcrumb: [
                    {
                        text: 'Horairestypessites',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/identifications',
            name: 'identifications',
            component: ()=> AllViews.Imports.ReadIdentifications(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Identifications',
                breadcrumb: [
                    {
                        text: 'Identifications',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/imports',
            name: 'imports',
            component: ()=> AllViews.Imports.ReadImports(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Imports',
                breadcrumb: [
                    {
                        text: 'Imports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/joursferies',
            name: 'joursferies',
            component: ()=> AllViews.Imports.ReadJoursferies(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Joursferies',
                breadcrumb: [
                    {
                        text: 'Joursferies',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/journals',
            name: 'journals',
            component: ()=> AllViews.Imports.ReadJournals(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Journal pointages ',
                hideCreate: true,
                breadcrumb: [
                    {
                        text: 'Journals',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/journalstransactions',
            name: 'journalstransactions',
            component: ()=> AllViews.Imports.ReadJournalsTransactions(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Journal transactions',
                hideCreate: true,
                breadcrumb: [
                    {
                        text: 'Journal transaction',
                        active: true,
                    },
                ],
            },
        },
        ,
        {
            path: '/journalssessions',
            name: 'journalssessions',
            component: ()=> AllViews.Imports.ReadJournalsSessions(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Journal sessions',
                hideCreate: true,
                breadcrumb: [
                    {
                        text: 'Journal sessions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/lignes',
            name: 'lignes',
            component: ()=> AllViews.Imports.ReadLignes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Lignes',
                breadcrumb: [
                    {
                        text: 'Lignes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/lignesmoyenstransports',
            name: 'lignesmoyenstransports',
            component: ()=> AllViews.Imports.ReadLignesmoyenstransports(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Lignesmoyenstransports',
                breadcrumb: [
                    {
                        text: 'Lignesmoyenstransports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/listings',
            name: 'listings',
            component: ()=> AllViews.Imports.ReadListings(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Tuto Video',
                breadcrumb: [
                    {
                        text: 'Listings',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/logins',
            name: 'logins',
            component: ()=> AllViews.Imports.ReadLogins(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Logins',
                breadcrumb: [
                    {
                        text: 'Logins',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/logs',
            name: 'logs',
            component: ()=> AllViews.Imports.ReadLogs(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Logs',
                breadcrumb: [
                    {
                        text: 'Logs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/maps',
            name: 'maps',
            component: ()=> AllViews.Imports.ReadMaps(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Maps',
                breadcrumb: [
                    {
                        text: 'Maps',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/maintenances',
            name: 'maintenances',
            component: ()=> AllViews.Imports.ReadMaintenances(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Maintenances',
                breadcrumb: [
                    {
                        text: 'Maintenances',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/matrimoniales',
            name: 'matrimoniales',
            component: ()=> AllViews.Imports.ReadMatrimoniales(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Matrimoniales',
                breadcrumb: [
                    {
                        text: 'Matrimoniales',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/menus',
            name: 'menus',
            component: ()=> AllViews.Imports.ReadMenus(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Menus',
                breadcrumb: [
                    {
                        text: 'Menus',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/model_has_permissions',
            name: 'model_has_permissions',
            component: ()=> AllViews.Imports.ReadModel_has_permissions(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Model_has_permissions',
                breadcrumb: [
                    {
                        text: 'Model_has_permissions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/model_has_roles',
            name: 'model_has_roles',
            component: ()=> AllViews.Imports.ReadModel_has_roles(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Model_has_roles',
                breadcrumb: [
                    {
                        text: 'Model_has_roles',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/modelslistings',
            name: 'modelslistings',
            component: ()=> AllViews.Imports.ReadModelslistings(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Planifications',
                isProgrammations: true,
                breadcrumb: [
                    {
                        text: 'Modelslistings',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/moyenstransports',
            name: 'moyenstransports',
            component: ()=> AllViews.Imports.ReadMoyenstransports(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Moyenstransports',
                breadcrumb: [
                    {
                        text: 'Moyenstransports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/nationalites',
            name: 'nationalites',
            component: ()=> AllViews.Imports.ReadNationalites(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Nationalites',
                breadcrumb: [
                    {
                        text: 'Nationalites',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/oauth_access_tokens',
            name: 'oauth_access_tokens',
            component: ()=> AllViews.Imports.ReadOauth_access_tokens(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Oauth_access_tokens',
                breadcrumb: [
                    {
                        text: 'Oauth_access_tokens',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/oauth_auth_codes',
            name: 'oauth_auth_codes',
            component: ()=> AllViews.Imports.ReadOauth_auth_codes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Oauth_auth_codes',
                breadcrumb: [
                    {
                        text: 'Oauth_auth_codes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/oauth_clients',
            name: 'oauth_clients',
            component: ()=> AllViews.Imports.ReadOauth_clients(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Oauth_clients',
                breadcrumb: [
                    {
                        text: 'Oauth_clients',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/oauth_personal_access_clients',
            name: 'oauth_personal_access_clients',
            component: ()=> AllViews.Imports.ReadOauth_personal_access_clients(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Oauth_personal_access_clients',
                breadcrumb: [
                    {
                        text: 'Oauth_personal_access_clients',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/oauth_refresh_tokens',
            name: 'oauth_refresh_tokens',
            component: ()=> AllViews.Imports.ReadOauth_refresh_tokens(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Oauth_refresh_tokens',
                breadcrumb: [
                    {
                        text: 'Oauth_refresh_tokens',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/passagesrondes',
            name: 'passagesrondes',
            component: ()=> AllViews.Imports.ReadPassagesrondes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Passagesrondes',
                breadcrumb: [
                    {
                        text: 'Passagesrondes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/pastilles',
            name: 'pastilles',
            component: ()=> AllViews.Imports.ReadPastilles(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Pastilles',
                breadcrumb: [
                    {
                        text: 'Pastilles',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/permissions',
            name: 'permissions',
            component: ()=> AllViews.Imports.ReadPermissions(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Permissions',
                breadcrumb: [
                    {
                        text: 'Permissions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/permissionsdetails',
            name: 'permissionsdetails',
            component: ()=> AllViews.Imports.ReadPermissionsdetails(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Permissionsdetails',
                breadcrumb: [
                    {
                        text: 'Permissionsdetails',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/perms',
            name: 'perms',
            component: ()=> AllViews.Imports.ReadPerms(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Perms',
                breadcrumb: [
                    {
                        text: 'Perms',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/pointages',
            name: 'pointages',
            component: ()=> AllViews.Imports.ReadPointages(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Pointages',
                breadcrumb: [
                    {
                        text: 'Pointages',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/pointeuses',
            name: 'pointeuses',
            component: ()=> AllViews.Imports.ReadPointeuses(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Pointeuses',
                breadcrumb: [
                    {
                        text: 'Pointeuses',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/terminaux',
            name: 'terminaux',
            component: ()=> AllViews.Imports.ReadTerminaux(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Terminaux',
                breadcrumb: [
                    {
                        text: 'Terminaux',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/pointeusestransactions',
            name: 'pointeusestransactions',
            component: ()=> AllViews.Imports.ReadPointeusestransactions(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Pointeusestransactions',
                breadcrumb: [
                    {
                        text: 'Pointeusestransactions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/points',
            name: 'points',
            component: ()=> AllViews.Imports.ReadPoints(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Points',
                breadcrumb: [
                    {
                        text: 'Points',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/positions',
            name: 'positions',
            component: ()=> AllViews.Imports.ReadPositions(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Positions',
                breadcrumb: [
                    {
                        text: 'Positions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/postes',
            name: 'postes',
            component: ()=> AllViews.Imports.ReadPostes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Postes',
                hideCreate: true,
                breadcrumb: [
                    {
                        text: 'Postes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/postesagents',
            name: 'postesagents',
            component: ()=> AllViews.Imports.ReadPostesagents(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Postesagents',
                breadcrumb: [
                    {
                        text: 'Postesagents',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/postesarticles',
            name: 'postesarticles',
            component: ()=> AllViews.Imports.ReadPostesarticles(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Postesarticles',
                breadcrumb: [
                    {
                        text: 'Postesarticles',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/postesglobals',
            name: 'postesglobals',
            component: ()=> AllViews.Imports.ReadPostesglobals(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Postesglobals',
                breadcrumb: [
                    {
                        text: 'Postesglobals',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/postespointeuses',
            name: 'postespointeuses',
            component: ()=> AllViews.Imports.ReadPostespointeuses(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Postespointeuses',
                breadcrumb: [
                    {
                        text: 'Postespointeuses',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/presences',
            name: 'presences',
            component: ()=> AllViews.Imports.ReadPresences(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Presences',
                breadcrumb: [
                    {
                        text: 'Presences',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/prestations',
            name: 'prestations',
            component: ()=> AllViews.Imports.ReadPrestations(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Prestations',
                breadcrumb: [
                    {
                        text: 'Prestations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/preuves',
            name: 'preuves',
            component: ()=> AllViews.Imports.ReadPreuves(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Preuves',
                breadcrumb: [
                    {
                        text: 'Preuves',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/processus',
            name: 'processus',
            component: ()=> AllViews.Imports.ReadProcessus(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Processus',
                breadcrumb: [
                    {
                        text: 'Processus',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/programmations',
            name: 'programmations',
            component: ()=> AllViews.Imports.ReadProgrammations(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Aperçue listings',
                breadcrumb: [
                    {
                        text: 'Programmations',
                        active: true,
                    },
                ],
                isListings: true,
                validation: true,
            },
        },
        {
            path: '/programmations_LISTINGS_Valider1',
            name: 'programmations_LISTINGS_Valider1',
            component: ()=> AllViews.Imports.ReadProgrammations(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Listings valider 1',
                validation1: true,
                isListings: true,
                isListingvalidations: true,
                filter: [{
                    type: 'listings',
                },
                ],
                breadcrumb: [
                    {
                        text: 'Programmations',
                        active: true,
                    },
                ],
            },
        },

        {
            path: '/programmations_LISTINGS_Valider2',
            name: 'programmations_LISTINGS_Valider2',
            component: ()=> AllViews.Imports.ReadProgrammations(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Listings valider 2',
                validation2: true,
                isListings: true,
                isListingvalidations: true,
                filter: [{
                    type: 'listings',
                },
                ],
                breadcrumb: [
                    {
                        text: 'Programmations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/programmationsdetails',
            name: 'programmationsdetails',
            component: ()=> AllViews.Imports.ReadProgrammationsdetails(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Programmationsdetails',
                breadcrumb: [
                    {
                        text: 'Programmationsdetails',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/programmationsrondes',
            name: 'programmationsrondes',
            component: ()=> AllViews.Imports.ReadProgrammationsrondes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Programmationsrondes',
                breadcrumb: [
                    {
                        text: 'Programmationsrondes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/programmes',
            name: 'programmes',
            component: ()=> AllViews.Imports.ReadProgrammes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Programmes',
                breadcrumb: [
                    {
                        text: 'Programmes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/programmesrondes',
            name: 'programmesrondes',
            component: ()=> AllViews.Imports.ReadProgrammesrondes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Programmesrondes',
                breadcrumb: [
                    {
                        text: 'Programmesrondes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/provinces',
            name: 'provinces',
            component: ()=> AllViews.Imports.ReadProvinces(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Provinces',
                breadcrumb: [
                    {
                        text: 'Provinces',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/rapportpostes',
            name: 'rapportpostes',
            component: ()=> AllViews.Imports.ReadRapportpostes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Rapportpostes',
                breadcrumb: [
                    {
                        text: 'Rapportpostes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/rapports',
            name: 'rapports',
            component: ()=> AllViews.Imports.ReadRapports(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Rapports Pointages ',
                breadcrumb: [
                    {
                        text: 'Rapports',
                        active: true,
                    },
                ],
                filter: [
                    {
                        type: "valider",
                    },
                ],
                statsType: 'Pointages'
            },
        },
        {
            path: '/rapportsvacations',
            name: 'rapportsvacations',
            component: ()=> AllViews.Imports.ReadRapportsVacations(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Rapports Vacations ',
                breadcrumb: [
                    {
                        text: 'Rapports',
                        active: true,
                    },
                ],
                filter: [
                    {
                        type: "valider",
                    },
                ],
                statsType: 'Pointages'
            },
        },
        {
            path: '/role_has_permission',
            name: 'role_has_permission',
            component: ()=> AllViews.Imports.ReadRole_has_permission(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Role_has_permission',
                breadcrumb: [
                    {
                        text: 'Role_has_permission',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/role_has_permissions',
            name: 'role_has_permissions',
            component: ()=> AllViews.Imports.ReadRole_has_permissions(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Role_has_permissions',
                breadcrumb: [
                    {
                        text: 'Role_has_permissions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/roles',
            name: 'roles',
            component: ()=> AllViews.Imports.ReadRoles(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Roles / Profils',
                breadcrumb: [
                    {
                        text: 'Roles',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/reservations',
            name: 'reservations',
            component: ()=> import('@/views/customs/Reservations/ReadReservations/ReadReservations.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Reservations / Profils',
                breadcrumb: [
                    {
                        text: 'Reservations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/services',
            name: 'services',
            component: ()=> AllViews.Imports.ReadServices(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Services',
                breadcrumb: [
                    {
                        text: 'Services',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sexes',
            name: 'sexes',
            component: ()=> AllViews.Imports.ReadSexes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Sexes',
                breadcrumb: [
                    {
                        text: 'Sexes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sites',
            name: 'sites',
            component: ()=> AllViews.Imports.ReadSites(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Sites',
                breadcrumb: [
                    {
                        text: 'Sites',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sitespointeuses',
            name: 'sitespointeuses',
            component: ()=> AllViews.Imports.ReadSitespointeuses(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Sitespointeuses',
                breadcrumb: [
                    {
                        text: 'Sitespointeuses',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sitessdeplacements',
            name: 'sitessdeplacements',
            component: ()=> AllViews.Imports.ReadSitessdeplacements(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Sitessdeplacements',
                breadcrumb: [
                    {
                        text: 'Sitessdeplacements',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/situations',
            name: 'situations',
            component: ()=> AllViews.Imports.ReadSituations(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Situations',
                breadcrumb: [
                    {
                        text: 'Situations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/soldables',
            name: 'soldables',
            component: ()=> AllViews.Imports.ReadSoldables(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Soldables',
                breadcrumb: [
                    {
                        text: 'Soldables',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/statszones',
            name: 'statszones',
            component: ()=> AllViews.Imports.ReadStatszones(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Statszones',
                breadcrumb: [
                    {
                        text: 'Statszones',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/Supportclientreponses',
            name: 'supportclientreponses',
            component: ()=> AllViews.Imports.ReadSupportclientreponses(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Supportclientreponses',
                breadcrumb: [
                    {
                        text: 'Supportclientreponses',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/Supportclientrequettes',
            name: 'supportclientrequettes',
            component: ()=> AllViews.Imports.ReadSupportclientrequettes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Supportclientrequettes',
                breadcrumb: [
                    {
                        text: 'Supportclientrequettes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/surveillances',
            name: 'surveillances',
            component: ()=> AllViews.Imports.ReadSurveillances(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Logs',
                breadcrumb: [
                    {
                        text: 'Surveillances',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/switchsusers',
            name: 'switchsusers',
            component: ()=> AllViews.Imports.ReadSwitchsusers(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Switchsusers',
                breadcrumb: [
                    {
                        text: 'Switchsusers',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/terminals',
            name: 'terminals',
            component: ()=> AllViews.Imports.ReadTerminals(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Terminals',
                breadcrumb: [
                    {
                        text: 'Terminals',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/urbains',
            name: 'urbains',
            component: ()=> AllViews.Imports.ReadTrajets(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Trajets Urbains',
                breadcrumb: [
                    {
                        text: 'Urbains',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/interurbains',
            name: 'interurbains',
            component: ()=> AllViews.Imports.ReadVoyages(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Trajets Interurbains',
                breadcrumb: [
                    {
                        text: 'Interurbains',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/trackings',
            name: 'trackings',
            component: ()=> AllViews.Imports.ReadTrackings(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Trackings',
                breadcrumb: [
                    {
                        text: 'Trackings',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/transactionhistoriques',
            name: 'transactionhistoriques',
            component: ()=> AllViews.Imports.ReadTransactionhistoriques(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Transactionhistoriques',
                breadcrumb: [
                    {
                        text: 'Transactionhistoriques',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/transactions',
            name: 'transactions',
            component: ()=> AllViews.Imports.ReadTransactions(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Transactions',
                breadcrumb: [
                    {
                        text: 'Transactions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/transporteurs',
            name: 'transporteurs',
            component: ()=> AllViews.Imports.ReadTransporteurs(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Transporteurs',
                breadcrumb: [
                    {
                        text: 'Transporteurs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/transporteurstrajets',
            name: 'transporteurstrajets',
            component: ()=> AllViews.Imports.ReadTransporteurstrajets(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Transporteurstrajets',
                breadcrumb: [
                    {
                        text: 'Transporteurstrajets',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/travailleurs',
            name: 'travailleurs',
            component: ()=> AllViews.Imports.ReadTravailleurs(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Travailleurs',
                breadcrumb: [
                    {
                        text: 'Travailleurs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/types',
            name: 'types',
            component: ()=> AllViews.Imports.ReadTypes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Types',
                breadcrumb: [
                    {
                        text: 'Types',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesabscences',
            name: 'typesabscences',
            component: ()=> AllViews.Imports.ReadTypesabscences(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typesabscences',
                breadcrumb: [
                    {
                        text: 'Typesabscences',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesagentshoraires',
            name: 'typesagentshoraires',
            component: ()=> AllViews.Imports.ReadTypesagentshoraires(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typesagentshoraires',
                breadcrumb: [
                    {
                        text: 'Typesagentshoraires',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typeseffectifs',
            name: 'typeseffectifs',
            component: ()=> AllViews.Imports.ReadTypeseffectifs(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typeseffectifs',
                breadcrumb: [
                    {
                        text: 'Typeseffectifs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesheures',
            name: 'typesheures',
            component: ()=> AllViews.Imports.ReadTypesheures(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typesheures',
                breadcrumb: [
                    {
                        text: 'Typesheures',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesmoyenstransports',
            name: 'typesmoyenstransports',
            component: ()=> AllViews.Imports.ReadTypesmoyenstransports(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typesmoyenstransports',
                breadcrumb: [
                    {
                        text: 'Typesmoyenstransports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typespointages',
            name: 'typespointages',
            component: ()=> AllViews.Imports.ReadTypespointages(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typespointages',
                breadcrumb: [
                    {
                        text: 'Typespointages',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typespostes',
            name: 'typespostes',
            component: ()=> AllViews.Imports.ReadTypespostes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typespostes',
                breadcrumb: [
                    {
                        text: 'Typespostes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typessites',
            name: 'typessites',
            component: ()=> AllViews.Imports.ReadTypessites(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typessites',
                breadcrumb: [
                    {
                        text: 'Typessites',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typestaches',
            name: 'typestaches',
            component: ()=> AllViews.Imports.ReadTypestaches(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typestaches',
                breadcrumb: [
                    {
                        text: 'Typestaches',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesventilations',
            name: 'typesventilations',
            component: ()=> AllViews.Imports.ReadTypesventilations(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typesventilations',
                breadcrumb: [
                    {
                        text: 'Typesventilations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesequipements',
            name: 'typesequipements',
            component: ()=> AllViews.Imports.ReadTypespointeuses(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Types equipements',
                breadcrumb: [
                    {
                        text: 'Types equipements',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/usagers',
            name: 'usagers',
            component: ()=> AllViews.Imports.ReadUsagers(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Les usagers',
                breadcrumb: [
                    {
                        text: 'Usagers',
                        active: true,
                    },
                ],
                type: 'USAGERS',
                filter: [{
                    type: "usagers",
                },],
            },
        },
        {
            path: '/users',
            name: 'users',
            component: ()=> AllViews.Imports.ReadUsers(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Les users',
                breadcrumb: [
                    {
                        text: 'Users',
                        active: true,
                    },
                ],
                type: 'USERS',
                filter: [{
                    type: "users",
                },],
            },
        },
        {
            path: '/users_SU',
            name: 'users_SU',
            component: ()=> AllViews.Imports.ReadUsers(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Les super admin',
                breadcrumb: [
                    {
                        text: 'Users',
                        active: true,
                    },
                ],
                type: 'SU',
                filter: [{
                    type: "su",
                },],
            },
        },
        {
            path: '/usersgraphiques',
            name: 'usersgraphiques',
            component: ()=> AllViews.Imports.ReadUsersgraphiques(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Usersgraphiques',
                breadcrumb: [
                    {
                        text: 'Usersgraphiques',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/userstypespostes',
            name: 'userstypespostes',
            component: ()=> AllViews.Imports.ReadUserstypespostes(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Userstypespostes',
                breadcrumb: [
                    {
                        text: 'Userstypespostes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/userszones',
            name: 'userszones',
            component: ()=> AllViews.Imports.ReadUserszones(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Userszones',
                breadcrumb: [
                    {
                        text: 'Userszones',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/vehicules',
            name: 'vehicules',
            component: ()=> AllViews.Imports.ReadVehicules(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Vehicules',
                breadcrumb: [
                    {
                        text: 'Vehicules',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/vacations',
            name: 'vacations',
            component: ()=> AllViews.Imports.ReadVacations(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Vacations',
                breadcrumb: [
                    {
                        text: 'Vacations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/villes',
            name: 'villes',
            component: ()=> AllViews.Imports.ReadVilles(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Villes',
                breadcrumb: [
                    {
                        text: 'Villes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/ventilations',
            name: 'ventilations',
            component: ()=> AllViews.Imports.ReadVentilations(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Traitements des heures travaillées',
                breadcrumb: [
                    {
                        text: 'Ventilations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/works',
            name: 'works',
            component: ()=> AllViews.Imports.ReadWorks(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Works',
                breadcrumb: [
                    {
                        text: 'Works',
                        active: true,
                    },
                ],
            },
        },
        {

            path: '/zones',
            name: 'zones',
            component: ()=> AllViews.Imports.ReadZones(),
            meta: {
                requiresAuth: true,
                pageTitle: 'Zones',
                breadcrumb: [
                    {
                        text: 'Zones',
                        active: true,
                    },
                ],
            },
        },

    ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

router.beforeEach((to, from, next) => {
    console.log('Voici les paramètres de la route ==> ', to, from);

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Récupérer les informations nécessaires depuis le store
        const user = store.state.app.user; // Utilisation directe du store
        const token = user?.token;
        const permissions = user?.permissions;
        console.log('User dans le store :', store.state.app.user);
        // Vérification des conditions d'authentification et permissions
        if (!user || !token || !permissions || permissions.length === 0) {
            console.warn('Utilisateur non authentifié ou permissions manquantes');
            
            // Rediriger vers la page de connexion
            next({
                path: '/login',
                query: { redirect: to.fullPath } // Sauvegarder la route d'origine
            });
        } else {
            console.log('Utilisateur authentifié, permissions valides');
            next(); // Autoriser l'accès à la route demandée
        }
    } else {
        // Si la route ne nécessite pas d'authentification, continuer la navigation
        next();
    }
});
export default router
